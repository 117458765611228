import { jsPDF } from 'jspdf'

function leadingZero(number) {
    return (number < 10 && number > 0 ? '0' + number : number)
}
function formatDate(date) {
    return [leadingZero(date.getDate()), leadingZero(date.getMonth() + 1), date.getFullYear()].join('/')
}

function loadImageAsBase64(url, callback) {
	const xhr = new XMLHttpRequest()
	xhr.onload = function() {
		var reader = new FileReader()
		reader.onloadend = function() {
			callback(reader.result)
		}
		reader.readAsDataURL(xhr.response)
	}
	xhr.open('GET', url)
	xhr.responseType = 'blob'
	xhr.send()
}

export async function downloadCertificateForUser(user, finishedDate, certificateTexts) {
	if (!user) {
		return
	}

	const customerName = user.groups?.[0]?.group.customer?.name

	// Load background image
	const url = require('../assets/pics/certificate_background.jpg')

	loadImageAsBase64(url, (dataUrl) => {
		const doc = new jsPDF({
			orientation: 'landscape',
			unit: 'pt',
		})

		const pageWidth = doc.internal.pageSize.getWidth()
		const pageHeight = doc.internal.pageSize.getHeight()
		const xCenter = (pageWidth / 2)
		const textOptions = {
			align: 'center',
		}

		// Add background image
		doc.addImage(dataUrl, 'JPEG', 0, 0, pageWidth, pageHeight)

		// Add title and subtitle
		doc.setFontSize(30)
		doc.setTextColor('#d6006a')
		doc.text(certificateTexts.title, xCenter, 121, textOptions)

		doc.setFontSize(17)
		doc.setTextColor('#393939')
		doc.text(certificateTexts.subtitle, xCenter, 182, textOptions)

		// Add user first and last name
		doc.setFont('helvetica', 'normal', 'bold')
		doc.setFontSize(24)
		doc.text(user.first_name + ' ' + user.last_name + (customerName ? ' de ' + customerName : ''), xCenter, 250, textOptions)

		// Add text
		doc.setFontSize(17)
		doc.text(certificateTexts.text, xCenter, 312, textOptions)

		// Add finish date
		doc.setFont('helvetica', 'normal', 'normal')
		doc.setFontSize(11)
		doc.text('Le ' + formatDate(finishedDate), 236, 392, textOptions)

		// Add director signature and description
		doc.text(certificateTexts.signature, 604, 392, textOptions)
		doc.setFontSize(8)
		doc.text(certificateTexts.description, 100, 462)

		doc.save('Attestation COPROXI.pdf')
	})
}
